module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GetWidget Marketplace","short_name":"GetWidget Marketplace","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/favicon.png","icons":[{"src":"favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2504bcd094ddcdc4006b0df0f7949d63"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
